<template>
  <v-menu v-if="!readonly">
    <template v-slot:activator="{ on: menu }">
      <v-chip class="ma-2" :color="statusColor" outlined v-on="{ ...menu }">
        {{ statusDisplay }}
      </v-chip>
    </template>
    <v-list>
      <v-list-item
        v-for="(status, index) in statusEnumeration"
        :key="index"
        @click="
          item.status = status.name;
          updateStatus();
        "
      >
        <v-list-item-title>{{ status.display }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-chip class="ma-2" :color="statusColor" outlined v-else>
    {{ statusDisplay }}
  </v-chip>
</template>

<script>
import { stringify } from "query-string";

//const API_URL_ENTITY = process.env.VUE_APP_BASE_API_URL + "/contract";

export default {
  name: "status",
  props: {
    statusEnumeration: { type: Array },
    entity: { type: String },
    readonly: { type: Boolean, default: false },
    obj: { type: Object, default: null }
  },
  data: () => ({
    statusEnumerationDemo: [
      {
        name: "new",
        display: "Новый",
        color: "dark blue blue--text text--darken-1"
      },
      {
        name: "signed",
        display: "Подписано",
        color: "dark green green--text text--darken-1"
      },
      {
        name: "archived",
        display: "В архиве",
        color: "dark grey grey--text text--darken-1"
      }
    ],
    item: { status: "unknown" },
    parentId: null
  }),
  computed: {
    statusDisplay() {
      const d = this.statusEnumeration.find(x => x.name == this.item.status);
      return d ? d.display : "Не определен";
    },
    statusColor() {
      const d = this.statusEnumeration.find(x => x.name == this.item.status);
      return d ? d.color : "gray";
    }
  },
  methods: {
    updateStatus() {
      // eslint-disable-next-line no-console
      console.log(`updateStatus(${JSON.stringify(this.item)})`);
      // make post request
      fetch(`${process.env.VUE_APP_BASE_API_URL}/${this.entity}`, {
        method: "POST",
        body: JSON.stringify(this.item),
        headers: {
          "content-type": "application/json"
        }
      })
        .then(resp => resp.json())
        .then(result => {
          // eslint-disable-next-line no-console
          console.log("response is ");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            // update item view
            this.item.status = result.status;
          }
        });
    }
  },
  created() {
    if (this.obj != undefined) {
      this.item._id = this.obj._id;
      this.item.status = this.obj.status;
    }
  },
  mounted() {
    // eslint-disable-next-line no-console
    console.log("mounted() " + stringify(this.obj));
    // is _id already defined then skip mounted
    if (this.item._id) {
      return;
    }
    fetch(
      `${process.env.VUE_APP_BASE_API_URL}/${this.entity}?${stringify(
        this.$route.params
      )}`
    )
      .then(resp => resp.json())
      .then(resp_json => {
        // eslint-disable-next-line no-console
        console.log("Load " + stringify(resp_json));
        // only status!
        this.item._id = resp_json._id;
        this.item.status = resp_json.status;
      });
  }
};
</script>
