<template>
<!-- валидация названия органиазцзии [а-яА-Я\s\d\u0022\u00AB\u00BB]-->
  <table-root-entities
    :entities="contracts"
    :caption="caption"
    :enableRating="false"
    :headers="config.entities.find(i=>i.name==='contract').headers"
    :statusEnumeration="config.entities.find(i=>i.name==='contract').status"
    :filters="config.entities.find(i=>i.name==='contract').filters"
    :properties="fields"
    keyField="title"
    textNewItem="Новый договор"
    newItemCaption="Новый договор"
    newItemInputLabel="название организации контрагента"
    newItemInputValidation="^.+$"
    textOneItem="договор"
    textManyItems="договоры"
    urlOpenItemEndpoint="contract"
    v-on:onNewItemSubmit="onAddNewItemSubmit"
  />
</template>

<script>
import TableRootEntities from "@/components/TableRootEntities.vue";

export default {
  components: {
    TableRootEntities,
  },
  data: () => ({
    contracts: [],
    fields: [
      { icon: "mdi-clock", field: "dateStart" },
      { icon: "mdi-clock", field: "dateFinish" },
    ],
    headers: []
  }),
  methods: {
    caption(item) {
      return item.title;
    },
    onAddNewItemSubmit(contract) {
      // eslint-disable-next-line no-console
      console.log("onAddNewItemSubmit + " + contract);
      fetch(process.env.VUE_APP_BASE_API_URL + "/contracts", {
        method: "POST",
        body: JSON.stringify(contract),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            this.contracts.push(result);
          }
          // clear
          //this.newEmployeeLastnameFirstname = "";
          //this.newItemMode = false;
        });
    },
  },
  created() {
    //console.log("find " + JSON.stringify(this.config.entities.find(i=>i.name==="contract")));
    this.headers = this.config.entities.find(i=>i.name==="contract").headers;
  },
  mounted() {
    fetch(process.env.VUE_APP_BASE_API_URL + "/contracts")
      .then((resp) => resp.json())
      .then((resp_json) => {
        // eslint-disable-next-line no-console
        console.log(resp_json);
        // save contracts
        this.contracts = resp_json;
      });
  },
};
</script>
