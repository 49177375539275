
import Status from "@/components/Status.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

const API_URL = process.env.VUE_APP_BASE_API_URL + "/contract";


@Component({
  components: {
    Status
  }
})
export default class TableRootEntities extends Vue {

  @Prop({ type: String, default: "" })
  name!: string;

  @Prop({ type: Array, default: () => [], required: true })
  entities!: Array<any>;

  @Prop({ type: Array, default: () => [], required: true })
  headers!: Array<any>; // TODO, typezy it

  @Prop({ type: Array, default: () => [], required: false })
  properties!: Array<any>; // TODO, typezy it

  @Prop({ type: Boolean, default: false, required: false })
  enableRating!: boolean;

  @Prop({ type: Function, required: false, default: () => "" })
  caption!: (item: any) => string;

  // caption for one item
  @Prop({ type: String, default: "" })
  textOneItem!: string;

  // key field for searching
  @Prop({ type: String, default: "" })
  keyField!: string;

  // caption for new item
  @Prop({ type: String, default: "" })
  textNewItem!: string;

  @Prop({ type: String, default: "" })
  newItemCaption!: string;

  @Prop({ type: String, default: "" })
  newItemInputLabel!: string;

  @Prop({ type: String, default: "" })
  newItemInputValidation!: string;

  @Prop({ type: String, default: "" })
  textManyItems!: string;

  // endpoint of service to fetch a list and add new item
  @Prop({ type: String, default: "" })
  urlEndpoint!: string;

  // endpoint of service open an item
  @Prop({ type: String, default: "" })
  urlOpenItemEndpoint!: string;

  @Prop({ type: Array, default: () => [] })
  statusEnumeration!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  filters!: Array<any>;


  isNewItemMode: boolean = false;
  searchText: string = "";
  selectedFilter: any = null;
  newItem: {
    title: null | string,
  } = { title: null };
  valid: boolean = false;
  dialogDelete: boolean = false;
  editedIndex: number = -1;

  // ??
  editedItem: any;
  defaultItem: any;

  get search() {
    return (
      (this.selectedFilter != null ? this.selectedFilter.display + "," : "") +
      this.searchText
    );
  };

  emit<T>(eventName: string, value: T) {
    return new Promise((resolve) => {
      this.$emit(eventName, value);
      this.$nextTick(resolve);
    });
  }
  open<T extends { _id: string }>(item: T, data: any) {
    const isCtrlKey = (window as any).event.ctrlKey;
    console.log(`open ctrl ${isCtrlKey} url ${this.urlOpenItemEndpoint}`);
    if (isCtrlKey) { // todo добавить кнопку для mac os
      let routeData = this.$router.resolve({
        name: this.urlOpenItemEndpoint,
        params: { id: item._id },
      });
      window.open(routeData.href, "_blank");
    } else {
      this.$router.push({
        name: this.urlOpenItemEndpoint,
        params: { id: item._id },
      });
    }
  }
  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  save(e: any) {
    const refForm: any = this.$refs.formNewItem;
    if (!refForm.validate()) return;
    //if (!e.valid) return;
    // if (this.editedIndex > -1) {
    //   Object.assign(this.desserts[this.editedIndex], this.editedItem);
    // } else {
    //   this.desserts.push(this.editedItem);
    // }
    this.addNewItem();
  }
  addNewItem() {
    console.log("addNewItem ", this.newItem);
    this.emit("onNewItemSubmit", this.newItem).then(() => {
      console.log("switch off edit mode");
      this.isNewItemMode = false;
    });
  }
  filterOnlyCapsText(value: any, search: any, item: any) {
    var filtered =
      // должен быть элемент в целом
      item != null &&
      // ключевое поле должно включать в себя искомый текст, если он задан
      (item[this.keyField]
        .toString()
        .toLowerCase()
        .indexOf(this.searchText.toLowerCase()) !== -1 ||
        this.searchText.length == 0) &&
      // если задан фильтр по статусу, то искать фильтровать по статусу
      (this.selectedFilter == null ||
        this.selectedFilter.names.find(
          (i: any) => i === (item.status !== undefined ? item.status : "undefined")
        ) !== undefined);
    // if (filtered) {
    //   console.log("filterOnlyCapsText " + value + " ["+ search+"]" + JSON.stringify(item) + " filterStatus " + this.filterStatus.name);
    // }
    return filtered;
  }


  created() {
    this.newItem.title = null;
    //this.selectedFilter = Object.assign({}, this.filters);
    this.selectedFilter =
      this.filters != null && this.filters.length > 0
        ? this.filters.find((i) => i.default)
        : null;
  }
  mounted___() {
    fetch(process.env.VUE_APP_BASE_API_URL + "/" + this.urlEndpoint)
      .then((resp) => resp.json())
      .then((resp_json) => {
        // eslint-disable-next-line no-console
        console.log(resp_json);

        this.entities = resp_json;
      });
  }
}
